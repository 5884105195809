<template>
  <div class="social-login">
    <!-- <span class="social-button">
      <vue-telegram-login
        mode="callback"
        telegram-login="psyh0test_bot"
        radius="4"
        @callback="telegramLogin"
      />
    </span> -->
    <span class="social-button">
      <!-- <a-button
        style="background: rgb(58,88,152);color: white;"
        type="primary"
        @click="AuthProvider('facebook')"
      >
        <a-icon type="facebook" />
        Sign In with Facebook
      </a-button> -->
      <v-facebook-login app-id="2369262256718631" @login="facebookLogin" />
    </span>
    <span class="social-button-second">
      <a-button
        style="
          background: rgb(206, 69, 61);
          color: white;
          width: 100%;
          height: 42px;
        "
        type="primary"
        @click="signInWithGoogle"
      >
        <!-- @click="signInWithGoogle" -->

        <a-icon type="google" />
        Sign In with Google
      </a-button>
    </span>
  </div>
</template>
<script>
// import { vueTelegramLogin } from "vue-telegram-login";
import { VFBLogin as VFacebookLogin } from "vue-facebook-login-component";

import { mapMutations } from "vuex";

export default {
  name: "SocialLogin",
  components: { /*vueTelegramLogin,*/ VFacebookLogin },
  methods: {
    async updateProfile(response) {
      if (
        response.user &&
        response.user.username &&
        !response.user.firstname &&
        !response.user.secondname
      ) {
        const usernameSplit = response.user.username
          .split(" ")
          .filter(function (el) {
            return el !== null && el !== "";
          });
        /* eslint-disable no-console */
        // console.log(response.user.username, usernameSplit);
        const data = {
          id: response.user.id,
          firstname: usernameSplit.length > 0 ? usernameSplit[0] : undefined,
          secondname: usernameSplit.length > 1 ? usernameSplit[1] : undefined,
        };
        await this.$store.dispatch("authCustom/updateProfileAction", data);
        await this.$store.dispatch("authCustom/authAction");
      }
    },
    telegramLogin(user) {
      //console.log(user);

      this.$store
        .dispatch("authCustom/telegramLogin", user)
        .then((response) => {
          //console.log(response);
          this.updateProfile(response).then(() => {
            this.$notification["success"]({
              message: this.$t("ui.SuccessOccurred"),
              description: false,
            });
            this.$router.push("/profile");
          });
        })
        .catch((err) => {
          this.$notification["error"]({
            message: this.$t("ui.ErrorOccurred"),
            description: false,
          });
        });
    },
    facebookLogin(response) {
      if (
        response &&
        response.status &&
        response.authResponse &&
        response.status === "connected"
      ) {
        return this.$store
          .dispatch("authCustom/facebookLogin", response.authResponse)
          .then((response) => {
            /* eslint-disable no-console */
            //console.log(response);

            this.$notification["success"]({
              message: this.$t("ui.SuccessOccurred"),
              description: false,
            });
            this.$router.push("/profile");
          })
          .catch((err) => {
            this.$notification["error"]({
              message: this.$t("ui.ErrorOccurred"),
              description: false,
            });
          });
      }
    },
    async signInWithGoogle() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
          // console.log('user', GoogleUser)

          return this.$store
            .dispatch("authCustom/googleLogin", GoogleUser)
            .then((response) => {
              /* eslint-disable no-console */
              // console.log(response);
              this.updateProfile(response).then(() => {
                this.$notification["success"]({
                  message: this.$t("ui.SuccessOccurred"),
                  description: false,
                });
                this.$router.push("/profile");
              });
            })
            .catch((err) => {
              this.$notification["error"]({
                message: this.$t("ui.ErrorOccurred"),
                description: false,
              });
            });
        })
        .catch((error) => {
          // console.log(error);
          // on fail do something
        });
    },
    ...mapMutations({ setActionButton: "ui/setAuthCall" }),
  },
  created() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "2369262256718631",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v15.0",
      });
    };
  },
};
</script>
<style lang="scss" scoped>

.social-login {
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.social-button-second,
.social-button {
  // width: 260px;
  padding: 10px;
  // height: 55px;
  // width: 240px;
  margin-top: 10px;
  height: 42px;
}

.social-button-second {
  width: 260px;
  height: 42px;
  margin-top: 10px;
}

.fa-google path {
  fill: url(#grad1);
}
.fa-google + .fa-google path {
  fill: url(#grad2);
}
.icon-svg {
  display: inline-block;
  position: relative;
}
.fa-google + .fa-google {
  position: absolute;
  left: 0;
  top: 0;
  clip-path: polygon(0% 0%, 120% 0%, 0% 75%);
}
</style>
